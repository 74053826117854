import { useTranslation } from "react-i18next";
import styles from "./Footer.module.scss";

export const Footer = () => {
	const { t } = useTranslation();

	return (
		<footer className={styles.Footer}>
			<a href={t("Footer.privacy.url")} target="_blank" rel="noreferrer">
				{t("Footer.privacy.label")}
			</a>
			<a href={t("Footer.faq.url")} target="_blank" rel="noreferrer">
				{t("Footer.faq.label")}
			</a>

			<small>&copy; a.s.r.</small>
		</footer>
	);
};
