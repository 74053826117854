import { useTranslation } from "react-i18next";
import styles from "./Header.module.scss";

export const Header = () => {
	const { t } = useTranslation();

	return (
		<header className={styles.Header}>
			<a href={t("Header.logo.url")} target="_blank" rel="noreferrer">
				<img className={styles.Logo} src="/logo.svg" alt="a.s.r. Vitality" />
			</a>
		</header>
	);
};
