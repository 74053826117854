import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      Header: {
        logo: {
          url: "https://www.asrnederland.nl",
        },
      },

      Footer: {
        privacy: {
          url: "https://www.asrnederland.nl/privacyverklaring",
          label: "Privacy",
        },

        faq: {
          url: "https://www.asrnederland.nl/faq",
          label: "FAQ",
        },
      },

      NotFoundScreen: {
        title: "Whoops, the page you are looking for is not found",
        description: "Please try again.",
      },

      ResetPasswordScreen: {
        initialize: {
          title: "You have requested to reset your password",
          description: "Please fill in your new password below.",
          button: "Reset password",
          password: "Password",
          password_confirmation: "Repeat password",

          validate: "Password does not match confirmation",
          required: "Not all required fields are filled in",
          pattern:
            "Password does not match one lowercase, one uppercase, one digit, one special char, length 8 - 24",
        },

        success: {
          title: "Your password has succesfully been reset!",
          description: "You can now log in with your new password.",
        },

        error: {
          title: "Your password could not be reset!",
          description:
            "Something went wrong. Please try again or request a new reset password link.",
        },
      },

      VerifyEmailScreen: {
        success: {
          title: "Your account has succesfully been activated!",
          description: "You can now log in to the app with this account.",
        },

        error: {
          title: "Your account could not be activated!",
          description: "Please try again.",
        },
      },
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
