import { BrowserRouter, Switch, Route } from "react-router-dom";

import {
	ResetPasswordScreen,
	VerifyEmailScreen,
	NotFoundScreen,
} from "./screens";

import "./i18n";
import "./App.scss";

function App() {
	return (
		<BrowserRouter>
			<Switch>
				<Route path="/verify-email" component={VerifyEmailScreen} />
				<Route path="/reset-password" component={ResetPasswordScreen} />

				<Route path="*" component={NotFoundScreen} />
			</Switch>
		</BrowserRouter>
	);
}

export default App;
