import { API } from "api";
import { Page, Paragraph, Title } from "components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

enum Status {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  LOADING = "LOADING",
}

export const VerifyEmailScreen = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<Status>(Status.LOADING);
  const query = useLocation();

  useEffect(() => {
    (async () => {
      try {
        const params = new URLSearchParams(query.search);

        await API.get(`/auth/verify/${params.get("token")}`);
        setStatus(Status.SUCCESS);
      } catch (e) {
        setStatus(Status.ERROR);
      }
    })();
  }, [query.search]);

  return (
    <Page>
      {status === Status.LOADING && <Title>...</Title>}

      {status === Status.SUCCESS && (
        <>
          <Title>{t("VerifyEmailScreen.success.title")}</Title>
          <Paragraph>{t("VerifyEmailScreen.success.description")}</Paragraph>
        </>
      )}

      {status === Status.ERROR && (
        <>
          <Title>{t("VerifyEmailScreen.error.title")}</Title>
          <Paragraph>{t("VerifyEmailScreen.error.description")}</Paragraph>
        </>
      )}
    </Page>
  );
};
