import { API } from "api";
import { Button, Page, Paragraph, Title, Input, Error } from "components";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

enum Status {
	SUCCESS = "SUCCESS",
	ERROR = "ERROR",
	INITIALIZE = "INITIALIZE",
}

export const ResetPasswordScreen = () => {
	const { t } = useTranslation();
	const [status, setStatus] = useState<Status>(Status.INITIALIZE);
	const query = useLocation();

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm();

	const password = useRef({});
	password.current = watch("password", "");

	const error = errors[Object.keys(errors)[0]];

	const onSubmit = async (values: any) => {
		try {
			const params = new URLSearchParams(query.search);

			await API.post(`/auth/reset_password`, {
				token: params.get("token"),
				email: params.get("email"),
				...values,
			});

			setStatus(Status.SUCCESS);
		} catch (e) {
			setStatus(Status.ERROR);
		}
	};

	return (
		<Page>
			{status === Status.INITIALIZE && (
				<>
					<Title>{t("ResetPasswordScreen.initialize.title")}</Title>

					<Paragraph>
						{t("ResetPasswordScreen.initialize.description")}
					</Paragraph>

					<form onSubmit={handleSubmit(onSubmit)}>
						<Input
							id="password"
							label={t("ResetPasswordScreen.initialize.password")}
							type="password"
							{...register("password", {
								required: true,
								pattern:
									/(?=^.{8,24}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_\-+}{&;:;'?/&.,<>])(?!.*\s).*$/g,
							})}
						/>

						<Input
							id="password_confirmation"
							label={t("ResetPasswordScreen.initialize.password_confirmation")}
							type="password"
							{...register("password_confirmation", {
								validate: (value) => value === password.current,
							})}
						/>

						{error && error.type === "required" && (
							<Error>{t("ResetPasswordScreen.initialize.required")}</Error>
						)}

						{error && error.type === "pattern" && (
							<Error>{t("ResetPasswordScreen.initialize.pattern")}</Error>
						)}

						{error && error.type === "validate" && (
							<Error>{t("ResetPasswordScreen.initialize.validate")}</Error>
						)}

						<Button type="submit">
							{t("ResetPasswordScreen.initialize.button")}
						</Button>
					</form>
				</>
			)}

			{status === Status.SUCCESS && (
				<>
					<Title>{t("ResetPasswordScreen.success.title")}</Title>
					<Paragraph>{t("ResetPasswordScreen.success.description")}</Paragraph>
				</>
			)}

			{status === Status.ERROR && (
				<>
					<Title>{t("ResetPasswordScreen.error.title")}</Title>
					<Paragraph>{t("ResetPasswordScreen.error.description")}</Paragraph>
				</>
			)}
		</Page>
	);
};
