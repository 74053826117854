import { Footer, Header } from "components";
import styles from "./Page.module.scss";

interface Props {
	children: any;
}

export const Page = ({ children }: Props) => {
	return (
		<div className={styles.Page}>
			<Header />

			<div className={styles.Content}>{children}</div>

			<Footer />
		</div>
	);
};
